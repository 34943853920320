/* Global styles */
@font-face {
  font-family: "GTEestiProDisplay";
  src: local("GTEestiProDisplay"),
    url("../fonts/GT-Eesti-Display-Regular.otf") format("opentype");
  font-weight: normal;
}

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

.cookie-monster-bg {
  position: fixed;
  top: 50%; /* Center the image vertically */
  right: -50px;
  transform: translate(0, -50%) rotate(-90deg); /* Rotate and reposition the image */
  width: 200px; /* Set the width of your image */
  height: auto; /* Maintain aspect ratio */
  z-index: -1; /* Place the image behind other elements */
  scale: 2;
}

body {
  /* No need to repeat the color scheme definitions here as they are already set inline on the <body> tag */
  margin: 0;
  padding: 0;
  font-family: "GTEestiProDisplay";
  background-color: #121212;
}

.header-wrapper {
  width: 90%; /* Set the width of the header wrapper */
  margin: 0 auto; /* Center the header wrapper horizontally */
}

.header {
  margin-top: 20px;
  background-color: #1b1b1b;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 10px; /* Add rounded corners to the header */
  box-shadow: -4px -3px 45px 21px rgba(0, 0, 0, 0.35);
}

.header-inner {
  display: flex;
  align-items: center;
}

/* Logo styles */
.logo {
  display: flex;
  align-items: center;
}

.App-logo {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.shop-name {
  font-family: "GTEestiProDisplay";
  margin-left: 10px;
  margin-right: 80px;
  font-weight: bold;
  font-size: 20px;
  color: var(--primaryColor_main);
}

/* Navbar styles */
.navbar {
  margin-left: 20px;
}

.nav-button {
  font-family: "GTEestiProDisplay";
  background-color: transparent;
  border: none; /* Remove the border */
  border-radius: 10px;
  color: var(--primaryColor_main);
  padding: 8px 16px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.nav-button::before {
  font-family: "GTEestiProDisplay";
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  border-radius: 10px; /* Add rounded corners to the pseudo-element */
}

.nav-button:hover::before {
  opacity: 1;
}

.nav-button-active {
  font-family: "GTEestiProDisplay";
  background-color: transparent;
  border: none; /* Remove the border */
  border-radius: 10px;
  color: var(--primaryColor_main);
  padding: 8px 16px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #000;
  background: rgb(241, 245, 249);
  background: linear-gradient(
    0deg,
    rgba(241, 245, 249, 1) 0%,
    rgba(245, 171, 72, 1) 100%
  );
  transform: scale(1.1);
}

/* Corrected the hover rule to change the background color */
.nav-button:hover {
  font-family: "GTEestiProDisplay";
  color: #000;
  background: rgb(241, 245, 249);
  background: linear-gradient(
    0deg,
    rgba(241, 245, 249, 1) 0%,
    rgba(245, 171, 72, 1) 100%
  );
  transform: scale(1.1);
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the start of the container */
  margin-top: 20px;
}

.product-item {
  font-family: "GTEestiProDisplay";
  width: 300px;
  height: auto;
  background-color: #1b1b1b;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 2.95px 2.95px 3.6px;
}

/* Adjust margin for the last item in each row */
.product-item:nth-child(n) {
  margin-right: 10px; /* Set a smaller margin for items in the middle */
}

/* Reset margin for the last item in each row */
.product-item:nth-child(n)::nth-last-child(1),
.product-item:nth-child(n)::nth-last-child(2),
.product-item:nth-child(n)::nth-last-child(3) {
  margin-right: 50px; /* Restore the larger margin for the last items */
}

.product-image {
  width: 100%;
  height: 150px; /* Set initial image height */
  object-fit: cover; /* Ensure the image fills the container */
  border-radius: 10px;
}

.product-name {
  font-family: "GTEestiProDisplay";
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  color: #f5f5f5;
}

.product-description {
  font-family: "GTEestiProDisplay";
  margin-top: 5px;
  color: var(--secondary_text_color);
}

.buy-button {
  font-family: "GTEestiProDisplay";
  background-color: rgba(245, 171, 72, 1);
  color: #000;
  border: none;
  border-radius: 10px;
  padding: 8px 0;
  width: 80%;
  font-size: 16px;
  cursor: pointer;
  display: block; /* Ensure the button takes up the full width of the product item */
  margin: auto; /* Center the button horizontally */
  text-align: center; /* Center the text within the button */
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none !important; /* Remove the underline from the text */
}

.buy-button:hover {
  background-color: var(--primaryColor_main);
  color: var(--light_color);
}

.welcome-section {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 90px;
}

.welcome-text {
  font-family: "GTEestiProDisplay";
  font-size: 60px;
  font-weight: bold;
  color: var(--primaryColor_main);
}

.welcome-subtext {
  font-family: "GTEestiProDisplay";
  font-size: 20px;
  color: var(--secondary_text_color);
  margin-top: 5px;
}

.go-shopping-button {
  font-family: "GTEestiProDisplay";
  background-color: transparent;
  border: none; /* Remove the border */
  border-radius: 10px;
  color: var(--primaryColor_main);
  padding: 8px 16px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #000;
  background: rgb(241, 245, 249);
  background: linear-gradient(
    0deg,
    rgba(241, 245, 249, 1) 0%,
    rgba(245, 171, 72, 1) 70%
  );
  transform: scale(1.1);
}

.go-shopping-button:hover {
  font-family: "GTEestiProDisplay";
  color: #000;
  background: rgb(173, 116, 41);
}

.separator {
  text-align: center;
  margin-top: 20px;
}

.separator h2 {
  font-size: 40px;
  color: var(--primaryColor_main);
  background-color: var(--light_color);
  display: inline-block;
  padding: 0 10px;
  position: relative;
  top: -10px;
}

.support-info {
  width: 60%;
  margin: auto;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(241, 245, 249, 1) 0%,
    rgba(245, 171, 72, 1) 60%
  );
}

.support-content h3 {
  font-size: 40px;
  color: black;
  margin-bottom: 10px;
}

.support-content p {
  font-size: 20px;
  color: black;
  margin-bottom: 20px;
}

.contact-support-button {
  font-family: "GTEestiProDisplay";
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.contact-support-button:hover {
  background-color: #b1adad;
  color: black;
  transform: scale(1.2);
}

/* ReviewItem.css */
.reviews-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.review-item {
  font-family: "GTEestiProDisplay";
  width: 300px;
  background-color: var(--white_color);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 2.95px 2.95px 3.6px;
  transition: transform 0.3s;
  cursor: pointer;
}

.review-item:hover {
  transform: scale(1.05);
}

.rating-stars {
  color: var(--primaryColor);
  font-size: 20px;
  margin-bottom: 5px;
}

.review-message {
  font-family: "GTEestiProDisplay";
  font-size: 16px;
  margin-top: 10px;
  color: #f5f5f5;
}

.review-date {
  font-family: "GTEestiProDisplay";
  font-size: 14px;
  color: var(--secondary_text_color);
  margin-top: 5px;
}
